/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getHour = /* GraphQL */ `
  query GetHour($id: ID!) {
    getHour(id: $id) {
      id
      dateFrom
      dateTo
      qt
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listHours = /* GraphQL */ `
  query ListHours(
    $filter: ModelHourFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHours(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dateFrom
        dateTo
        qt
        note
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFileSent = /* GraphQL */ `
  query GetFileSent($id: ID!) {
    getFileSent(id: $id) {
      id
      sentDate
      dateFrom
      dateTo
      to
      note
      hours {
        id
        dateFrom
        dateTo
        qt
        note
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listFileSents = /* GraphQL */ `
  query ListFileSents(
    $filter: ModelFileSentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFileSents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sentDate
        dateFrom
        dateTo
        to
        note
        hours {
          id
          dateFrom
          dateTo
          qt
          note
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
