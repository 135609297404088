import React, { useState, useContext } from 'react';
import './App.css';
import { AppContext } from '../context/appContext';
// material
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { blue, teal, orange } from '@material-ui/core/colors';
// amplify
import { AmplifySignOut } from '@aws-amplify/ui-react';
//
import HourDash from '../components/Hour/hourDash';
import TopBar from '../components/TopBar';
import MenuDrawer from '../components/Drawer';

const theme = createMuiTheme({
    palette: {
        // primary: blue,
        primary: teal,
        // primary: {
        //     main: '#00897b',
        // },
        secondary: orange,
    },
});


function App() {
    console.log("Test");
    let { state: stateApp, dispatch } = useContext(AppContext);
    const [openDrawer, setOpenDrawer] = useState(false);

    const signOut = () => {
        dispatch({ 
            type: "signOut"
        });
    } 

    return (
        <ThemeProvider theme={theme}>
            <div className="Appold">
                <TopBar 
                    buttonOnRight={<AmplifySignOut handleAuthStateChange={signOut}/>}
                    openDrawer={() => setOpenDrawer(true)}
                />
                <MenuDrawer 
                    open={openDrawer}
                    toggleDrawer={(open) => setOpenDrawer(open)}
                />
                {/* <div>Hello, {user.username}</div> */}
                <HourDash />
            </div>
        </ThemeProvider>
    );
}

export default App;