import React from 'react';
import Hour from './index';

function HoursList (props) {

    return (
        <div>
            <h1>Hours List</h1>
            {props.listItems && props.listItems.length > 0 ?
                props.listItems.map((item,index) => {
                    return (
                        <div style={{display:'flex', flexDirection:'row'}} key={index}>
                            <Hour item={item}/>
                            <div style={{marginLeft:10,marginRight:10}}>
                                <button onClick={() => props.edit ? props.edit(item) : {}}>Modifica</button>
                            </div>
                            <div style={{marginLeft:10,marginRight:10}}>
                                <button onClick={() => props.deleteHour ? props.deleteHour(item) : {}}>Elimina</button>
                            </div>
                        </div>
                    )
                })
            :
                <div>nessun elemento</div>
            }
        </div>
    );
}

export default HoursList;