import React, { useState, useEffect } from 'react';
import { API, Storage } from 'aws-amplify';
import { listHours } from '../../graphql/queries';
import { deleteHour as deleteHourMutation } from '../../graphql/mutations';
import FormHour from './form';
import HoursList from './list';
import Button from '@material-ui/core/Button';

function HourDash (props) {
    const [state, setState] = useState({
        hours: [],
        editMode: false,
        itemToEdit: null
    });


    useEffect(() => {
        refreshList();
    },[]);

    const refreshList = async () => {
        const apiData = await API.graphql({ query: listHours });
        const hoursFromAPI = apiData.data.listHours.items;
        setState({
            hours: hoursFromAPI,
            editMode: false,
            itemToEdit: null
        })
    }

    const deleteHour = async ({ id }) => {
        await API.graphql({ query: deleteHourMutation, variables: { input: { id } }});
        refreshList();
    }

    return (
        <div>
            <div style={{display:'flex', flexDirection:'row',border:'1px solid red', padding:10}}>
                <span>Hour dashboard</span>
                <div style={{marginLeft:10,marginRight:10}}>
                    <Button variant="contained" color="primary" onClick={() => setState({ ...state, editMode: true })}>
                        Aggiungi
                    </Button>
                </div>
            </div>
            {state.editMode ? 
                <FormHour 
                    refreshList={refreshList} 
                    goBack={() => setState({ ...state, editMode: false, itemToEdit: null })}
                    item={state.itemToEdit}
                />
            : 
                <HoursList 
                    listItems={state.hours} 
                    deleteHour={deleteHour} 
                    edit={(item) => setState({ ...state, editMode: true, itemToEdit: item })}
                />
            }
        </div>
    );
}

export default HourDash;