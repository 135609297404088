import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import Login from './Auth/Login';
import App from './App';
import Page404 from './Pages/404';
import { AppContext } from './context/appContext';

const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => {
    console.log("Private Route", isLoggedIn);
    return <Route
        {...rest}
        render={props =>
            isLoggedIn ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: props.location }
                    }}
                />
            )
        }
    />
};

const Routes = () => {
    let { state } = React.useContext(AppContext);

    console.log("Router isLoggedIn", state.isLoggedIn)
    return (
        <Router>
            <div>
                <Switch>
                    <Route
                        exact
                        path={'/'}
                        render={() => <Login />}
                    />
                    <Route
                        exact
                        path={'/login'}
                        render={() => <Login />}
                    />
                    <PrivateRoute
                        path="/web"
                        component={App}
                        isLoggedIn={state.isLoggedIn}
                    />
                    <Route 
                        component={() => <Page404 />} 
                    />
                </Switch>
            </div>
        </Router>
    );
};

export default Routes;
