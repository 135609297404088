import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { createHour as createHourMutation, updateHour as updateHourMutation } from '../../graphql/mutations';
import './formStyle.css';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DDTHH:mm:ss.sssZ';

function FormHour (props) {
    const item = props.item ? props.item : null;
    const [formData, setFormData] = useState({
        dateFrom: item ? item.dateFrom : moment().format(dateFormat),
        dateTo: item ? item.dateTo : moment().add(1, 'hours').format(dateFormat), 
        qt: item ? item.qt : '', 
        note: item ? item.note : '',
    });

    async function createHour() {
        if (!formData.dateFrom) return;
        await API.graphql({ query: createHourMutation, variables: { input: formData } });
        resetForm();
        if (props.refreshList) props.refreshList();
    }

    async function updateHour() {
        if (!formData.dateFrom) return;
        await API.graphql({ 
            query: updateHourMutation, 
            variables: { 
                input: {
                    id: item.id,
                    ...formData
                }
            }
        });
        if (props.refreshList) props.refreshList();
    }

    const resetForm = () => {
        setFormData({
            ...formData,
            dateFrom: moment().format(dateFormat),
            dateTo: moment().add(1, 'hours').format(dateFormat), 
            qt: '', 
            note: '',
        })
    }
    
    return (
        <div className="form-wrapper">
            
            <h1>Insert Hour Form: </h1>
            <div className="form-container">
                <div className="form-row">
                    <label>Dalle: </label>
                    <input
                        onChange={e => setFormData({ ...formData, 'dateFrom': e.target.value})}
                        placeholder="dateFrom"
                        value={formData.dateFrom}
                    />
                </div>
                <div className="form-row">
                    <label>Alle: </label>
                    <input
                        onChange={e => setFormData({ ...formData, 'dateTo': e.target.value})}
                        placeholder="dateTo"
                        value={formData.dateTo}
                    />
                </div>
                <div className="form-row">
                    <label>Q.ta: </label>
                    <input
                        onChange={e => setFormData({ ...formData, 'qt': e.target.value})}
                        placeholder="qt"
                        value={formData.qt}
                    />
                </div>
                <div className="form-row">
                    <label>Note: </label>
                    <input
                        onChange={e => setFormData({ ...formData, 'note': e.target.value})}
                        placeholder="Note"
                        value={formData.note}
                    />
                </div>
            </div>
            <div className="form-buttons">
                <div style={{marginLeft:10,marginRight:10}}>
                    <button onClick={() => props.goBack ? props.goBack() : {}}>Indietro</button>
                </div>
                <div style={{marginLeft:10,marginRight:10}}>
                    <button onClick={() => resetForm()}>Reset</button>
                </div>
                <div style={{marginLeft:10,marginRight:10}}>
                    <button onClick={() => item ? updateHour() : createHour()}>Salva</button>
                </div>
            </div>
        </div>
    );
}

export default FormHour;