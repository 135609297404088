/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createHour = /* GraphQL */ `
  mutation CreateHour(
    $input: CreateHourInput!
    $condition: ModelHourConditionInput
  ) {
    createHour(input: $input, condition: $condition) {
      id
      dateFrom
      dateTo
      qt
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateHour = /* GraphQL */ `
  mutation UpdateHour(
    $input: UpdateHourInput!
    $condition: ModelHourConditionInput
  ) {
    updateHour(input: $input, condition: $condition) {
      id
      dateFrom
      dateTo
      qt
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteHour = /* GraphQL */ `
  mutation DeleteHour(
    $input: DeleteHourInput!
    $condition: ModelHourConditionInput
  ) {
    deleteHour(input: $input, condition: $condition) {
      id
      dateFrom
      dateTo
      qt
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createFileSent = /* GraphQL */ `
  mutation CreateFileSent(
    $input: CreateFileSentInput!
    $condition: ModelFileSentConditionInput
  ) {
    createFileSent(input: $input, condition: $condition) {
      id
      sentDate
      dateFrom
      dateTo
      to
      note
      hours {
        id
        dateFrom
        dateTo
        qt
        note
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateFileSent = /* GraphQL */ `
  mutation UpdateFileSent(
    $input: UpdateFileSentInput!
    $condition: ModelFileSentConditionInput
  ) {
    updateFileSent(input: $input, condition: $condition) {
      id
      sentDate
      dateFrom
      dateTo
      to
      note
      hours {
        id
        dateFrom
        dateTo
        qt
        note
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteFileSent = /* GraphQL */ `
  mutation DeleteFileSent(
    $input: DeleteFileSentInput!
    $condition: ModelFileSentConditionInput
  ) {
    deleteFileSent(input: $input, condition: $condition) {
      id
      sentDate
      dateFrom
      dateTo
      to
      note
      hours {
        id
        dateFrom
        dateTo
        qt
        note
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
