import React, { useState, useContext } from 'react';
import '../App/App.css';
// amplify
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { AppContext } from '../context/appContext';
import { useHistory } from "react-router-dom";

function Login() {
    console.log("Login");

    const [authState, setAuthState] = useState();
    const [user, setUser] = useState();
    let { state: stateApp, dispatch } = useContext(AppContext);
    let history = useHistory();

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []); 

    React.useEffect(() => {
        if (authState === AuthState.SignedIn && user) {
            console.log("user", user);
            dispatch({ 
                type: "signIn",
                payload: user
            });
            history.push("/web")
        }
    }, [authState, user]);

    return (
        <div>
            <AmplifyAuthenticator />
        </div>
    );
}

export default Login;