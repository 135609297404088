/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:05d9c837-4943-4da3-8f33-7b117fdedb8c",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_ib55092m4",
    "aws_user_pools_web_client_id": "2veu9ndg85f90v4o8itmtg7bc7",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://6caprymgd5hgtmlbnmyqrbmagm.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
