import React from "react";
import { AppContextProvider } from "./context/appContext";
import Routes from "./router";

function DashApp() {
    return (
        <AppContextProvider>
            <Routes />
        </AppContextProvider>
    );
}

export default DashApp;