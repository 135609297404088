import React from 'react';

function Hour (props) {
    const item = props.item ? props.item : {};

    return (
        <div style={{display:'flex', flexDirection:'row', marginBottom:20}}>
            <div style={{flex:1}}>id: {item.id}</div>
            <div style={{flex:2}}>dateFrom: {item.dateFrom}</div>
            <div style={{flex:2}}>dateTo: {item.dateTo}</div>
            <div style={{flex:1}}>qt: {item.qt}</div>
            <div style={{flex:1}}>note: {item.note}</div>
        </div>
    );
}

export default Hour;