import React, { useReducer } from "react";

let AppContext = React.createContext();

let initialState = {
    isLoggedIn: false,
    user: null, 
};

let reducer = (state, action) => {
    switch (action.type) {
        case "signIn":
            console.log("action.payloiad", action.payload);
            return { 
                ...state,
                isLoggedIn: true,
                user: action.payload
            };
        case "signOut":
            return { 
                ...state,
                isLoggedIn: false,
                user: null
            };
        default: 
            return initialState;
    }
};

function AppContextProvider(props) {
    let [state, dispatch] = useReducer(reducer, initialState);
    let value = { state, dispatch };

    return (
        <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
    );
}

export { AppContext, AppContextProvider };