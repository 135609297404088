import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { useHistory, Link } from "react-router-dom";

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export default function MenuDrawer(props) {
    const classes = useStyles();
    let history = useHistory();

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        props.toggleDrawer(open);
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem button>
                    <ListItemIcon>{<InboxIcon />}</ListItemIcon>
                    <ListItemText primary={"Ore"} onClick={() => history.push("/ore")}/>
                </ListItem>
                <ListItem button>
                    <ListItemIcon>{<MailIcon />}</ListItemIcon>
                    <ListItemText primary={"Riepilogo"} onClick={() => history.push("/riepilogo")}/>
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem button>
                    <ListItemIcon>{<InboxIcon />}</ListItemIcon>
                    <Link to="/ore">Ore</Link>
                </ListItem>
                <ListItem button>
                    <ListItemIcon>{<MailIcon />}</ListItemIcon>
                    <Link to="/riepilogo">Riepilogo</Link>
                </ListItem>
            </List>
            {/* <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List> */}
        </div>
    );

    return (
        <div>
            <React.Fragment>
                {/* <Button onClick={toggleDrawer(true)}>{anchor}</Button> */}
                <SwipeableDrawer
                    anchor={"left"}
                    open={props.open}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    {list()}
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}
